/* events-section */

.events-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
}

.events-section .container {
  padding: 0 11.11vw;
  width: 100%;
}

@media (min-width: 1440px) {
  .events-section .container {
    padding: 0 160px;
  }
}

@media (max-width: 767px) {
  .events-section .container {
    padding: 0 24px;
  }
}

.events-section .section-title {
  text-align: center;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 24px;
  color: #1179bf;
  font-weight: 700;
}

.events-section .section-subtitle {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin: 0 auto 60px;
}

@media (max-width: 767px) {
  .events-section .section-subtitle {
    margin: 0 auto 50px;
  }
}

.events-section .new-events {
  height: auto;
}

.new-events {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0;
}

.new-events__item a {
  position: relative;
  display: block;
  margin: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
  border-radius: 16px;
  background-position: 50%;
  background-size: cover;
  transition: all 0.5s;
  color: #ffffff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.16);
}

.new-events__item--1 {
  grid-area: 2 / 4 / 3 / 5;
}

.new-events__item--2 {
  grid-area: 1 / 4 / 2 / 5;
}

.new-events__item--3 {
  grid-area: 1 / 1 / 2 / 2;
  background-size: cover;
}

.new-events__item--4 {
  grid-area: 1 / 2 / 3 / 4;
}

.new-events__item--5 {
  grid-area: 2 / 1 / 3 / 2;
}

.new-events .bottom-text {
  position: absolute;
  bottom: 24px;
  left: 24px;
  margin-right: 24px;
}

.new-events .bottom-text h3 {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.new-events .bottom-text p {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 4px 0;
}

.new-events .bottom-text span {
  margin: 0;
  display: block;
  font-size: 14px;
  line-height: 17px;
}

.new-events a:before {
  content: none;
}

@media (max-width: 959px) {
  .new-events {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 14px;
    margin: 0;
  }

  .new-events__item--1 {
    grid-area: 4/2/4/3;
  }

  .new-events__item--2 {
    grid-area: 4/1/4/2;
  }

  .new-events__item--3 {
    grid-area: 3/1/3/2;
    background-size: cover;
  }

  .new-events__item--4 {
    grid-area: 1/1/3/3;
  }

  .new-events__item--5 {
    grid-area: 3/2/3/3;
  }
}

@media (max-width: 500px) {
  .new-events .bottom-text {
    bottom: 18px;
    left: 18px;
    margin-right: 18px;
  }
  .new-events .bottom-text h3 {
    width: 100%;
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 1.2;
  }
  .new-events .bottom-text p {
    font-size: 12px;
    line-height: 1.2;
  }
  .new-events .bottom-text span {
    font-size: 10px;
    line-height: 1.2;
  }
}

@media (max-width: 360px) {
  .new-events .bottom-text {
    bottom: 14px;
    left: 14px;
    margin-right: 14px;
  }
}

@media (max-width: 300px) {
  .new-events .bottom-text h3 {
    font-size: 14px;
  }
  .new-events .bottom-text p {
    font-size: 10px;
  }
  .new-events .bottom-text span {
    font-size: 8px;
  }
}

/* slider */

.events-section {
  flex-direction: column;
}

.new-events {
  display: flex;
  flex-wrap: wrap;
}

.posts-slider {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.new-events__item:nth-child(1) {
  width: 100%;
}

.posts-slider .new-events__item:nth-child(1),
.posts-slider .new-events__item {
  width: 50%;
  padding: 10px;
}

@media (max-width: 1199px) {
  .new-events {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    grid-gap: 0;
  }

  .new-events__item:nth-child(1) {
    width: 100%;
  }

  .container--posts-slider {
    padding: 0 24px;
  }

  .posts-slider {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  .posts-slider::-webkit-scrollbar {
    display: none;
  }

  .posts-slider {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .posts-slider .new-events__item,
  .posts-slider .new-events__item:first-child {
    flex-shrink: 0;
    width: 62.3%;
    margin: 24px 12px 0;
    padding: 0;
  }

  .posts-slider .posts-slide:nth-child(1) {
    margin-left: 0;
  }

  .posts-slider .posts-slide:nth-last-child(1) {
    margin-right: 0;
  }

  .posts-slider .posts-slide:nth-child(1) {
    order: 1;
  }

  .posts-slider .posts-slide:nth-child(2) {
    order: 2;
  }

  .posts-slider .posts-slide:nth-child(3) {
    order: 3;
  }

  .posts-slider .posts-slide:nth-child(4) {
    order: 4;
  }
}

/* slider */

.events-section {
  flex-direction: column;
}

.new-events {
  display: flex;
  flex-wrap: wrap;
}

.posts-slider {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.new-events__item:nth-child(1) {
  width: 100%;
}

.posts-slider .new-events__item:nth-child(1),
.posts-slider .new-events__item {
  width: 50%;
  padding: 10px;
}

@media (max-width: 1199px) {
  .new-events {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    grid-gap: 0;
  }

  .new-events__item:nth-child(1) {
    width: 100%;
  }

  .container--posts-slider {
    padding: 0 24px;
  }

  .posts-slider {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  .posts-slider::-webkit-scrollbar {
    display: none;
  }

  .posts-slider {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .posts-slider .new-events__item,
  .posts-slider .new-events__item:first-child {
    flex-shrink: 0;
    width: 62.3%;
    margin: 24px 12px 0;
    padding: 0;
  }

  .posts-slider .posts-slide:nth-child(1) {
    margin-left: 0;
  }

  .posts-slider .posts-slide:nth-last-child(1) {
    margin-right: 0;
  }
}

/* fix */

@media (min-width: 1200px) {
  .new-events {
    position: relative;
    width: 100%;
    padding-bottom: 50%;
  }

  .new-events .new-events__item {
    position: absolute;
  }

  .new-events .new-events__item--1 {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(50% - 10px);
    height: 100%;
    padding-bottom: 0;
  }

  .new-events .new-events__item--1 a {
    height: 100%;
    padding-bottom: 0;
  }

  .new-events .posts-slider .new-events__item--2,
  .new-events .posts-slider .new-events__item--3,
  .new-events .posts-slider .new-events__item--4,
  .new-events .posts-slider .new-events__item--5 {
    width: calc(25% - 10px);
    padding: 0;
  }

  .new-events .posts-slider .new-events__item--2 {
    top: 0;
    right: 0;
  }

  .new-events .posts-slider .new-events__item--3 {
    bottom: 0;
    right: 0;
  }

  .new-events .posts-slider .new-events__item--4 {
    top: 0;
    left: 0;
  }

  .new-events .posts-slider .new-events__item--5 {
    bottom: 0;
    left: 0;
  }
}
